const axios = require('axios');

import _ from 'lodash'
import Db from './loader.js';
import { notify } from '@/utils'
import { store } from "@/store/index";

const sessionTokenKeyname = 'sessionToken',
    userKeyname = 'userObj';

async function requireLogin () {
    let totken = await getSessionToken()
    if(!totken)
        router.replace({name: 'AccountLanding'})
}

async function storeUserObj(userObj) {
    try {
        await Db.configs.where('key').equalsIgnoreCase(userKeyname).delete()
        let r = await Db.configs.add({
            key: userKeyname,
            value: JSON.stringify(userObj)
        })
        let lectura = await getUserObj()
        return (JSON.stringify(lectura) == JSON.stringify(userObj))
    } catch (e) {
        console.error(e)
    }
}

async function storeSessionToken(sessionToken) {
    try {
        await Db.configs.where('key').equalsIgnoreCase(sessionTokenKeyname).delete()
        let r = await Db.configs.add({
            key: sessionTokenKeyname,
            value: sessionToken
        })
        let lectura = await getSessionToken()
        return (lectura == sessionToken)
    } catch (e) {
        console.error(e)
    }
}

async function getUserObj() {
    try {
        let userObj = await Db.configs.where('key').equalsIgnoreCase(userKeyname).first();
        if (_.isObjectLike(userObj))
            return JSON.parse(userObj['value'])
        else
            throw 'Lectura no exitosa'
    } catch (e) {
        console.warn(e)
    }
}

async function getSessionToken() {
    try {
        let sessionToken = await Db.configs.where('key').equalsIgnoreCase(sessionTokenKeyname).first();
        if (_.isObjectLike(sessionToken))
            return sessionToken['value']
        else
            throw 'Lectura no exitosa'
    } catch (e) {
        console.warn(e)
    }
}

async function updateStatement() {
    try {
        let user = await getUserObj();
        store.commit('accountSetUsuario', user)
        console.log('updateStatement', store, user)
        return user;
    } catch(e){
        notify(e)
    }
}

async function tryToLogin({ email, password }) {
    try {
        const response = await axios.post('account/trylogin', { email, password })
        if (response.status) {
            await Promise.all([
                storeUserObj(response.data),
                storeSessionToken(response.data.token)
            ])
            await updateStatement()
            return true;
        }
    } catch (e) {
        notify(e)
    }
}

export {
    sessionTokenKeyname,
    userKeyname,
    requireLogin,
    storeSessionToken,
    getSessionToken,
    updateStatement,
    tryToLogin
}