<template>
	<b-navbar id="main-nav" toggleable="lg">
		<b-navbar-brand>
			<img id="blackfon-portalcautivo-logo" src="@/assets/Exports/Blackfon-White over black - nonsquare.png" alt="Blackfon" />
		</b-navbar-brand>

		<MainNavAccount />

		<!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

		<b-collapse id="nav-collapse" is-nav>
			<b-navbar-nav class="ml-auto">
				<b-nav-item href="/">
					<i class="material-symbols-outlined">home</i>
				</b-nav-item>
			</b-navbar-nav>
		</b-collapse> -->
	</b-navbar>
</template>

<script>
import Vue from "vue";
import langSwitcher from './lang-switcher.vue'
import MainNavAccount from "./MainNavAccount.vue";
export default Vue.extend({
	name: "BlackfonMxNav",
	components: { langSwitcher, MainNavAccount },

});
</script>

<style lang="less">
#main-nav {
	text-transform: uppercase;
	position: relative;
	z-index: 100;
	border-bottom: 2px solid white;
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: space-around;

	a {
		color: white;
	}

	.material-symbols-outlined {
		margin-top: -1em;
		margin-bottom: -1em;
		position: relative;
		top: 0.25em;
	}

	#blackfon-portalcautivo-logo {
		height: 2.5em;
	}

	&.navbar-light {
		.navbar-toggler {
			border: none;
		}
	}
}
</style>