<template>
<div>
  <b-img src="https://cdn.discordapp.com/attachments/1100844489364484097/1100890866656235670/danyle_illustrate_an_artistic_cover_for_Privacy_Policy_or_safet_cf39d1b0-3da8-4ce7-9d6e-379381b19fe2.png" fluid/>
  <b-container>
    <h1 class="my-4">Aviso de Privacidad</h1>
    
    <b-card title="¿Para qué fines utilizaremos sus datos personales?" class="mt-4">
      <ol>
        <li>
          Registrar a los Usuarios de los servicios que proporciona la empresa; para que con ello pueda brindársele el acceso a la Aplicación de la misma.
        </li>
        <li>
          Que la empresa tenga identificado al Usuario a efecto de asignarle una cuenta y/o número de registro que permita al mismo utilizar sin contratiempo los servicios proporcionados por “Blackfoń rapientrega a domicilio, S.A. de C.V., a través de la Aplicación establecida en redes electrónicas.
        </li>
        <li>
          Dar cumplimiento a obligaciones contraídas con nuestros clientes.
        </li>
        <li>
          Informar sobre cambios de nuestros productos o servicios.
        </li>
        <li>
          Evaluar la calidad del servicio.
        </li>
        <li>
          Realizar estudios internos sobre hábitos de consumo.
        </li>
        <li>
          De manera secundaria, se usarán para informarle de las ofertas que pudieran tener los Aliados Comerciales con los que la empresa tiene relación. Para esta última finalidad, requerimos de su consentimiento, el cual podrá manifestar al momento de su registro.
        </li>
        <li>
          Preponderantemente sus datos personales serán utilizados para proveer los servicios y productos requeridos por Usted.
        </li>
      </ol>
    </b-card>

    <b-card title="¿Qué datos personales utilizaremos para estos fines?" class="mt-4">
      <ol>
        <li>Nombre.</li>
        <li>Domicilio.</li>
        <li>Correo electrónico.</li>
        <li>Domicilio de entrega.</li>
        <li>Registro Federal de Contribuyentes.</li>
      </ol>
    </b-card>

    <b-card title="¿Cómo puede ejercer sus derechos ARCO?" class="mt-4">
      <p>
        Usted podrá ejercer sus derechos de acceder a sus datos personales que poseemos y a los detalles del tratamiento de los mismos, así como a rectificarlos, en caso de ser inexactos o cancelarlos, cuando considere que resulten excesivos o innecesarios para las finalidades que justificaron su obtención, u oponerse al tratamiento de los mismos para fines determinados.
      </p>
      <p>
        Los mecanismos que se han implementado para el ejercicio de dichos derechos son a través de la presentación de la solicitud respectiva en nuestro domicilio, es necesario contar con mecanismos suficientes para acreditar su identidad, como la firma electrónica o cualquier otro medio para hacerlo.
      </p>
    </b-card>

    <b-card title="Transferencia de datos personales" class="mt-4">
      <p>
        Se informa que no se realizarán transferencias de datos personales, salvo aquéllas que sean necesarias para atender requerimientos de información de una autoridad competente, que estén debidamente fundados y motivados.
      </p>
    </b-card>

    <b-card title="Cambios en el aviso de privacidad" class="mt-4">
      <p>
        Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad, para la atención de novedades legislativas o jurisprudenciales, políticas internas, nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos y prácticas de mercado.
      </p>
      <p>
        Estas modificaciones estarán disponibles al público a través de la Aplicación correspondiente.
        La fecha de la última actualización al presente aviso de privacidad lo fue: (02/03/2022).
      </p>
    </b-card>
  </b-container>
</div>
</template>


<script>
export default {
  name: 'PrivacyPolicyView',
};
</script>