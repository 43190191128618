<template>
    <div id="mainNavAccount">
        <div class="textual">
            <div class="nombre">
                {{ accountGetUsuario.nombre }}
            </div>
            <div class="email">
                {{ accountGetUsuario.email }}
            </div>
        </div>
        <div class="img-holder">
            <img :src="accountGetUsuario.imagenes_id_avatar | img" >
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'mainNavAccount',
        computed: {
            ...mapGetters(['accountGetUsuario'])
        }
    }
</script>

<style lang="scss">
    @import '@/scss/app.scss';

    #mainNavAccount {
        display: flex;
        flex-flow: row;
        align-items: center;
        gap: 5px;
        .textual {
            flex: 1 0 auto;
            text-align: right;
            .nombre {
                font-size: 10px;
                color: $primary;
            }
            .email {
                font-size: 8px;
                color: $secondary;
            }
        }
        .img-holder {
            $imgSize: 3em;
            flex: 0 0 $imgSize;
            height: $imgSize;
            img {
                width: 100%; height: 100%;
                object-fit: cover;
                border-radius: 50%;
            }
        }
    }
</style>