import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store/index';
import { translate, isTranslated } from './utils.js';

require ('./dexie/loader.js')

import Vue2Filters from 'vue2-filters'
import BootstrapVue from 'bootstrap-vue'

require('./apiConfig')

import './scss/app.scss'
import { makeImage } from './apiConfig';
import componentAutoload from './component-autoload'


import { marked } from 'marked'
import sanitizeHtml from 'sanitize-html';

Vue.filter('marked', (content, sanitized = true) => {
	let html = (marked(content))
	return (sanitized) ? sanitizeHtml(html) : html;
})

Vue.filter('isTranslated', isTranslated);

Vue.filter('img', (img: [string, number, null], cfg: [string, undefined]) => { return makeImage(img, cfg) });
Vue.filter('translate', translate);
Vue.filter('i', translate);
Vue.filter('iDef', (a, cfg) => {
	return translate(a, cfg.label, cfg.default)
});

Vue.use(Vue2Filters)
Vue.use(BootstrapVue)
Vue.use(componentAutoload)

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
