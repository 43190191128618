type Translation = {
    [key: string]: string;
};

export const translation: Translation = {

    // LANDING
    'folio': 'Folio',
    'estatus': 'Estatus',
    'nombres': 'Nombre completo',
    'email': 'Correo electrónico',
    'personas': 'Personas (Comensales)',
    'total': 'Total',
    'landing_title': 'Bienvenido a Blackfon',
    'landing_desc': '¡Con Blackfon, tus sueños culinarios cobran vida! No esperes más y déjanos sorprenderte con nuestra pasión por la gastronomía.',
    'landing_cta': 'Descubre nuestro menú',
    'stripePagosId': 'ID de pago de Stripe',
    'FullAddress': 'Domicilio completo',
    'comentarios_cliente': 'Comentarios de cliente',
    'our_menu': 'Nuestro menú',
    'menu_information_incomplete': 'Debes llenar toda la configuración requerida del menú.',
    'Phone country': 'País del teléfono',
    'Phone': 'Teléfono',
    'full_phone': 'Teléfono completo',
    'Click here!': '¡Clic aquí!',
    'Configure your order': 'Configura tu orden',

    // NOMBRES DE MENÚ
    'agenda_inicio': 'Agenda de inicio',
    'agenda_horaservicio': 'Hora de servicio. (Cocina llegará 1 hora antes.)',
    'menu_descripcion': 'Descripción de menú',
    'main_dishes': 'Platos principales',
    'side_dishes': 'Complementos',
    'appetizers': 'Aperitivos',
    'entree': 'Entradas',
    'preparations': 'Preparados',
    'Persons': 'Personas',
    'Back': 'Atrás',
    'Next': 'Siguiente',
    'Client details': 'Detalles del cliente',
    'Service': 'Servicio',
    'Configure your menus': 'Configura tu menú',
    'Payment': 'Pagar',
    'Purchase summary': 'Resumen de compra',
    'Processing': 'Procesando',
    'Checkout': 'Revisar la compra',
    'Process payment': 'Procesar pago',
    'Select one': 'Selecciona una opción',
    'not_menus_found': '¡Menus no encontrados!',
    'not_menus_found_lead': 'Lo sentimos mucho pero no encontramos menús para trabajar en éste momento. Intenta nuevamente mas tarde.',
    'call_to_action': '¡Reserva ya!',
    'not_content_found': 'Contenido no encontrado',
    'not_content_found_lead': 'Por favor intenta mas tarde...',
    'zona': 'Zona',


    // CONTACT LEAD
    'contact_lead': 'Por favor, llena el siguiente formulario para ponerte en contacto con nosotros',

    // ORDER DETAIL
    'Order detail': 'Detalle de orden',

    // MINOR DETAILS
    'telefono_celular': 'Teléfono celular',
    'created': 'Fecha de creación',
    'modified': 'Fecha de última edición',
};
