<template>
	<!-- <b-footer> -->
	<footer id="footer" class="hide-on-print">
		<b-container>
			<div class="row row-centered-v">
				<div class="col">
					<div class="text-center">
						<links-viewer grupo="Izquierda" />
						<!-- <img id="blackfon-portalcautivo" src="@/assets/Exports/Culinary Simple.svg" alt="Blackfon"
							class="d-none d-md-inline" /> -->
						<p>
							<strong> Blackfon® {{ year }}</strong>
							<br />
							<i>{{ "Todos los derechos reservados." | i }}</i>
						</p>
					</div>
				</div>
				<div class="col">
					<div class="text-center">
						<links-viewer grupo="Centro" />
					</div>
				</div>
				<div class="col">
					<div class="text-center">
						<links-viewer grupo="Derecha" />
						<a href="https://dantrix.com.mx" class="Developed-by bebas-neue" target="_blank">
							<div class="block">
								<span class="element-with-gradient-font">Developed by:</span>
							</div>
							<div class="block">
								<img src="https://dantrix.com.mx/img/Dantrix_logo_compact.png" alt="Dantrix">
							</div>
						</a>
					</div>
				</div>
			</div>
		</b-container>
	</footer>
	<!-- </b-footer> -->
</template>

<script lang="ts">
import Vue from "vue";
import moment from "moment";
export default Vue.extend({
	name: "MainFooter",
	data: () => {
		return {
			year: moment().format("Y"),
		};
	},
});
</script>

<style lang="less">
#footer {
	margin: 0em auto;
	padding: 1em 0;
	color: var(--grey);
	@secondary: #f17f03;
	position: relative;
	z-index: 100;

	.element-with-gradient-font {
		// background: linear-gradient(to right, var(--primary), @secondary);
		background: linear-gradient(to bottom, #d7cdd3, #f17f03);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	p {
		margin-bottom: 0;
	}

	// background-color: var(--primary);
	#blackfon-portalcautivo {
		width: 6em;
	}

	.Developed-by {
		color: @secondary;
		background-color: red;
		position: relative;
		top: -0.5em;

		&:hover,
		&:active {
			text-decoration: none;
		}

		.block {
			display: block;

			span {
				position: relative;
				// top: 1em;
				// font-size: 1.25em;
				// left: -0.125em;
				top: 2em;
				font-size: 0.55em;
				left: -2.525em;
			}

			img {
				width: 10em;
			}
		}
	}

	@media screen and (max-width: 580px) {
		.row {
			// background-color: red;
			flex-flow: column;
		}
	}
}
</style>