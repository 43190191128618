<template>

    <div class="lang-switcher" v-if="$route.name != 'Booknow'">
        <span @click="set('en-US')" :class="{ active: (getSelectedLang() == 'EN') }">EN</span>
        |
        <span @click="set('es-MX')" :class="{ active: (getSelectedLang() == 'ES') }">ES</span>
    </div>

</template>

<script>
import { getSelectedLang } from '@/utils'

export default {
    name: 'lang-switcher',
    methods: {
        getSelectedLang,
        set(lang) {
            window.localStorage.setItem('language-rg', lang)
            this.$emit('reload')
        }
    },
    data: () => {
        return {
            selected: 'en-US'
        }
    },
    mounted() {
        this.selected = window.localStorage.getItem('language-rg')
    }
}
</script>

<style lang="less">
.lang-switcher {
    padding: 9px 27px;
    color: #c8c7c7;
    cursor: pointer;
    span {
        color: var(--gray);
        &.active {
            color: var(--primary);
        }
    }
    @media screen and (max-width: 991px){
        text-align: center;
        // background-color: red;
    }
}
</style>