// INITS
import Vue from "vue"
import Vuex from "vuex"

// VUE must know it´s going to us vuex.
Vue.use(Vuex);

// MODULES
import ui from './modules/ui'
import account from './modules/account'

/*
 * Modules is the way the state is divided for categorizing huge application in parts.
 *
 * For exampe, in a normal state aplication one part could be the entire user
 * access control handle by the "PROFILE" (or AUTH) module, and another part could be the
 * publications of the user handle by "POSTS" module.
 *
 * Of course, each module should serve as an holisitc autonomous.
 */
const modules = {
  ui, 
  account
};

// Root state object.
// Each Vuex instance is just a single state tree.
const state = {};

/*
    Getters are functions that are used to get specific items,
    for example filter a list of users by their las name, age, etc.
*/
const getters = {};

/*
 * Mutations are operations that actually mutates the state.
 *
 * Each mutation handler gets the entire state tree as the
 * first argument, followed by additional payload arguments.
 *
 * Mutations must be synchronous and can be recorded by plugins
 * for debugging purposes.
 */
const mutations = {};

/*
 * Actions are functions that cause side effects and can involve
 * asynchronous operations.
 */
const actions = {};

/*
 * A Vuex instance is created by combining the state, mutations, actions, getters and modules.
 */
export const store = new Vuex.Store({
	state,
	getters,
	actions,
	mutations,
	modules
});