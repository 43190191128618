/**
 * Utils.js
 * 
 * Some handy functions
 * 
 * Added quick function arrayToObject, swalMSG and mixedMSG
 * 
 * @version 2.0
 * @author Daniel Lepe
 * @date June 30th, 2023
 */
import mobiscroll from '@mobiscroll/javascript'
import swal from 'sweetalert'
import swal2 from 'sweetalert2'

import { translation as transEn } from './en'
import { translation as transEs } from './es'

import _ from 'lodash'

const emptySTR = '',
    Toast = swal2.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        // grow: 'column',
        timer: 8000
    });

const arrayToObject = (arr, key) => {
    return _.keyBy(arr, key);
}

const confirmFirst = async ({ text, title, goLabel, cancelLabel }) => {
    if (!goLabel) {
        goLabel = 'Continuar';
    }
    if (!cancelLabel) {
        cancelLabel = 'Cancelar';
    }
    let response = await swal({
        icon: 'info',
        title,
        text,
        buttons: {
            cancel: cancelLabel,
            danger: goLabel
        }
    })
    return response
}

const notify = (e) => {
    console.error(e)
    Toast.fire({
        type: 'warning',
        title: e
    });
    // mobiscroll.snackbar({
    //     display: 'top',
    //     // theme: 'ios',
    //     message: e,
    //     color: 'primary',
    //     duration: false
    // })
}

const toastMSG = (msg, classTXT) => {
    if (!!msg) {
        mobiscroll.snackbar({
            display: 'top',
            theme: 'ios',
            message: msg,
            color: (classTXT) ? classTXT : 'warning',
            duration: 3000
        })
    }
}

const swalMSG = (msg, classTXT) => {
    if (!!msg) {
        swal({
            title: msg,
            text: null,
            icon: (classTXT) ? classTXT : 'warning',
            buttons: {
                ok: 'OK',
            }
        })
    }
}

const mixedMSG = (status, msg, classTXT) => {
    if (!!msg) {
        if (!status) {
            swal({
                title: msg,
                text: null,
                icon: (classTXT) ? classTXT : 'warning',
                buttons: {
                    ok: 'OK',
                }
            })
        } else {
            Toast.fire({
                type: classTXT,
                title: msg
            });
        }
    }
}

const extractForm = (formArchetype) => {
    let ret = {};
    _.forEach(formArchetype, (field) => {
        if (field != null && _.isArray(field)) {
            _.forEach(field, (subField) => {
                ret[subField.key] = !!subField.default
                    ? subField.default
                    : null;
            });
        } else if (field != null && !!field.key) {
            ret[field.key] = !!field.default ? field.default : null;
        }
    });
    return ret;
};

function generarNombreClaseCSS(cadena) {
    let nombreClase = cadena;

    // Elimina los espacios en blanco al principio y al final
    nombreClase = nombreClase.trim();

    // Reemplaza los espacios en blanco por guiones
    nombreClase = nombreClase.replace(/\\s+/g, '-');

    // Elimina los caracteres no válidos
    nombreClase = nombreClase.replace(/[^a-zA-Z0-9\\-]/g, '');

    // Asegura que el primer carácter no sea numérico
    nombreClase = nombreClase.replace(/^[0-9]/, '');

    return nombreClase;
}

// MATRIX DEFINITION
const defaultLang = 'es-MX';

const getUserLang = () => {
    return window.localStorage.getItem('language-rg') || navigator.language || navigator.userLanguage || defaultLang;
}

const getSelectedLang = () => {
    const userLang = getUserLang();
    const regex = /es/i;  // 'i' flag makes it case-insensitive
    return (regex.test(userLang) ? 'ES' : 'EN');
    console.log('getSelectedLang', userLang, regex, )
}

const translate = (valueOrigin, label, defaultValue) => {
    try {
        const userLang = getUserLang()

        
        if (!_.isNil(userLang) && (userLang != window.localStorage.getItem('language-rg'))) {
            // console.log('Hard setting language to: ', userLang)
            window.localStorage.setItem('language-rg', userLang);
        } else {
            console.log('Nothing to do, userLang: ', userLang)
        }
        
        if (typeof valueOrigin === 'string') {
            let trans;

            if (getSelectedLang() == 'ES') {
                trans = transEs;
            } else {
                trans = transEn;
            }

            return trans[valueOrigin] || valueOrigin || emptySTR;
        } else if (typeof valueOrigin === 'object' && valueOrigin != null) {
            // LABEL VALIDATION
            if (typeof label === 'undefined') throw 'label not recieved!';
            const containingProp = label + ('_' + getSelectedLang().toLocaleLowerCase());
            if ((containingProp) in valueOrigin) {
                return valueOrigin[containingProp] || translate(defaultValue) || emptySTR;
            } else {
                return label
            }
        }
    } catch (e) {
        console.error(e)
        return valueOrigin
    }
}, isTranslated = (valueOrigin, label) => {
    let item = translate(valueOrigin, label);
    return (item) ? true : false;
    console.log('isTranslate.item', item, valueOrigin, label)
}

export {
    notify,
    toastMSG,
    swalMSG,
    confirmFirst,
    mixedMSG,
    arrayToObject,
    extractForm,
    generarNombreClaseCSS,
    getUserLang,
    getSelectedLang,
    translate,
    isTranslated
}