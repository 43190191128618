// IMPORTS
const _ = require('lodash');
const axios = require('axios');

// DEFINITIONS
const baseAPI = 'account/loadup';

interface State {
    usuario: {
        token: null,
        nombre: null,
        email: null,
        imagenes_id_avatar: null
    };
}
const state: State = {
    usuario: {
        token: null,
        nombre: null,
        email: null,
        imagenes_id_avatar: null
    },
};

const getters = {
    accountGetUsuario: (state: State) => {
        return state.usuario;
    },
    accountGetToken: (state: State) => {
        try {
            return state.usuario.token;
        } catch (e) {
            console.log('No state', e)
        }
    },
};

const mutations = {
    accountSetUsuario: (state: State, payload: any) => {
        state.usuario = payload;
    },
};

const actions = {

};

// EXPORTS
export default {
    state,
    getters,
    mutations,
    actions,
};