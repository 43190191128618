<template>
    <b-jumbotron id="features" v-if="cDisplay">
        <div class="container">
            <h2 class="bebas-neue">{{ title | translate }}</h2>
            <p class="lead">{{ "contact_lead" | i }}.</p>
            <hr class="my-4">
            <b-form @submit="onSubmit">
                <b-form-group label-cols="4" label-cols-lg="2" label-size="sm" id="name-group" :label="'Nombre' | i"
                    label-for="fullname-input">
                    <b-form-input id="fullname-input" type="text" v-model="form.fullname" required
                        :placeholder="'Ingrese su nombre' | i" />
                </b-form-group>
                <b-form-group label-cols="4" label-cols-lg="2" label-size="sm" id="email-group"
                    :label="'Correo electrónico' | i" label-for="email-input">
                    <b-form-input id="email-input" type="email" v-model="form.email" required
                        :placeholder="'Ingrese su correo electrónico' | i" />
                </b-form-group>
                <b-form-group label-cols="4" label-cols-lg="2" label-size="sm" id="phone-group" :label="'Teléfono' | i"
                    label-for="phone-input">
                    <b-form-input id="phone-input" type="tel" v-model="form.phone" required
                        :placeholder="'Ingrese su número de teléfono' | i" />
                </b-form-group>
                <b-form-group label-cols="4" label-cols-lg="2" label-size="sm" id="message-group" :label="'Mensaje' | i"
                    label-for="message-input">
                    <b-form-textarea id="message-input" v-model="form.message" rows="3" required
                        :placeholder="'Escriba su mensaje aquí' | i" />
                </b-form-group>
                <b-row>
                    <b-col offset-lg="2" offset-md="4">
                        <b-button type="submit" variant="primary" :disabled="isProcessing">
                            <span v-if="isProcessing">{{ "Procesando" | i }}...</span>
                            <span v-else>{{ "Enviar" | i }}</span>
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </div>
    </b-jumbotron>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import { mixedMSG, notify } from '@/utils';

const axios = require('axios');
const emptyForm = {
    fullname: null,
    email: null,
    phone: null,
    message: null,
}
export default Vue.extend({
    name: 'contactForm',
    data: () => {
        return {
            title: "Formulario de contacto",
            isProcessing: false,
            form: _.clone(emptyForm)
        }
    },
    computed: {
        cDisplay(){
            let display;
            switch(this.$route.name){
                case 'Booknow':
                    display = false;
                    break;
                default:
                    display = true;
                    break;
            }
            return display;
        }
    },
    methods: {
        async onSubmit($event) {
            $event.preventDefault()
            // IT SHOULDN´T WORK IF IT´S PROCESSING ALREADY!
            if (this.isProcessing) return;
            this.isProcessing = true;
            try {
                const response = await axios.post('contact/store', this.form)
                mixedMSG(response.status, response.msg, response.class)
                if (response.status)
                    this.clear()
            } catch (e) {
                notify(e)
                console.error(e)
            }
            this.isProcessing = false;
        },
        clear() {
            this.form = _.clone(emptyForm);
        }
    }
})
</script>
